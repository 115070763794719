.services-info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #011d5f67;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.services-info-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-weight: bold;
  font-size: 30px;
  margin: 10px;
  background: var(--color-primario);
  color: var(--color-secundario);
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s;
}

.services-info-modal-container {
  background-image: url("../../public/background_arrows.png");
  padding: 15px;
  position: relative;
  border-radius: 10px;
  width: 90%;
  text-align: center;

  @media (max-width: 1000px) {
    height: 95%;
  }
  @media (min-width: 1000px) {
    padding: 50px;
    height: 90%;
  }
}

.services-info-modal-title {
  text-transform: uppercase;
  background-color: var(--color-fondo);
  padding: 20px;
  color: var(--color-primario);
  width: max-content;
  max-width: 90vw;
  margin-bottom: 20px;
  border-radius: 5px;

  @media (max-width: 1000px) {
    font-size: small;
  }
}

.services-info-modal-intro {
  background: var(--color-primario);
  color: var(--color-fondo);
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    font-size: small;
  }

  @media (min-width: 1000px) {
    margin-bottom: 40px;
  }
}

.services-info-included {
  background-color: var(--color-fondo);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 40px;
}

.services-type-intro {
  text-align: left;
  margin-left: 20px;
}

.sevices-info-included-title {
  font-weight: 600;
  font-size: 30px;
  color: var(--color-primario);
  text-transform: uppercase;

  @media (max-width: 1000px) {
    font-size: medium;
  }

  @media (min-width: 1000px) {
    margin-bottom: 20px;
  }
}

.sevices-info-kind-title {
  text-transform: uppercase;
  color: var(--color-primario);
  text-align: left;
  margin-bottom: 20px;
  border-bottom: 5px solid;

  @media (max-width: 1000px) {
    font-size: small;
  }
}

.services-info-kind-list-title {
  align-items: center;
  justify-content: start;
  display: flex;
  margin-bottom: 10px;
}

.services-info-kind-list-check-icon {
  color: var(--color-primario);
  text-align: left;
  margin-right: 10px;
}

.services-info-kind-list-check-title {
  color: var(--color-primario);
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    font-size: small;
  }
}
.services-info-kind-list-description.expanded {
  white-space: normal; /* Permite que el texto se expanda */
  overflow: visible; /* Muestra todo el contenido */
  max-height: none; /* Remueve la restricción de altura */
}
.services-info-kind-list-description {
  color: var(--color-primario);
  text-align: left;
  margin-bottom: 10px;
  @media (max-width: 1000px) {
    font-size: small;
    overflow: hidden; /* Oculta el texto que exceda */
    white-space: pre; /* No permite saltos de línea */
    text-overflow: ellipsis; /* Muestra '...' al final del texto */
    max-height: 100px; /* Altura máxima */
    line-height: 1.5; /* Asegura un buen espaciado entre líneas */
    transition: max-height 0.3s ease; /* Transición suave al expandirse */
  }
}
.toggle-button-services-classic {
  background: none;
  border: none;
  color: var(--color-fondo); /* Ajusta según tu paleta */
  cursor: pointer;
  font-size: x-small;
  font-weight: 400;
  margin-top: 5px;
  text-transform: uppercase;
  transition: color 0.3s;
  margin-bottom: 20px;
}

.toggle-button-services {
  background: none;
  border: none;
  color: var(--color-primario); /* Ajusta según tu paleta */
  cursor: pointer;
  font-size: x-small;
  font-weight: 400;
  margin-top: 5px;
  text-transform: uppercase;
  transition: color 0.3s;
  margin-bottom: 20px;
}

.services-info-kind-content {
  margin-bottom: 20px;
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 40px;
  }
}

.services-info-kind-list-price {
  padding: 15px;
  color: var(--color-fondo);
  border-radius: 5px;
  background-color: var(--color-primario);
  margin-right: 15px;
}

.services-info-container {
  max-height: 83%;
  overflow: auto;

  @media (min-width: 1000px) {
    height: 75%;
  }
}

.services-info-image {
  object-fit: cover;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-style: solid;
  border-color: var(--color-primario);
  width: 420px;
  height: 300px;
}

.services-info-kind-content-classic {
  margin-bottom: 20px;
  background-color: var(--color-primario);
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 40px;
  }
}

.services-info-kind-list-title-classic {
  align-items: center;
  justify-content: start;
  display: flex;
  margin-bottom: 10px;
}

.services-info-kind-list-price-classic {
  padding: 15px;
  color: var(--color-primario);
  border-radius: 5px;
  background-color: var(--color-fondo);
  margin-right: 15px;
}

.services-info-kind-list-check-title-classic {
  color: var(--color-secundario);
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    font-size: small;
  }
}

.services-info-kind-list-description-classic {
  color: var(--color-fondo);
  text-align: left;
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    font-size: small;
    overflow: hidden; /* Oculta el texto que exceda */
    white-space: pre; /* No permite saltos de línea */
    text-overflow: ellipsis; /* Muestra '...' al final del texto */
    max-height: 100px; /* Altura máxima */
    line-height: 1.5; /* Asegura un buen espaciado entre líneas */
    transition: max-height 0.3s ease; /* Transición suave al expandirse */
  }
}
.services-info-kind-list-description-classic.expanded {
  white-space: normal; /* Permite que el texto se expanda */
  overflow: visible; /* Muestra todo el contenido */
  max-height: none; /* Remueve la restricción de altura */
}

.services-info-image-classic {
  object-fit: cover;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-style: solid;
  border-color: var(--color-primario);
  width: 420px;
  height: 300px;
}

.sevices-info-kind-title-classic {
  text-transform: uppercase;
  color: var(--color-secundario);
  text-align: left;
  margin-bottom: 20px;
  border-bottom: 5px solid;

  @media (max-width: 1000px) {
    font-size: small;
  }
}

.services-info-only-classic-container {
  background-color: var(--color-primario);
  padding: 20px;
  border-radius: 5px;
}

.services-info-only-container {
  padding: 20px;
}

/* Just one service modal info */
.service-info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #011d5f67;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.service-info-modal-container {
  background-image: url("../../public/background_arrows.png");
  padding: 15px;
  position: relative;
  border-radius: 10px;
  width: 90%;
  text-align: center;

  @media (max-width: 1000px) {
    padding: 20px;
    height: 70%;
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    padding: 50px;
    height: 85%;
  }

  @media (min-width: 1400px) {
    padding: 50px;
    height: 65%;
  }
}

.service-info-modal-intro {
  background: var(--color-primario);
  color: var(--color-fondo);
  margin-bottom: 20px;
  font-size: medium;
  text-align: justify;

  @media (max-width: 1000px) {
    font-size: medium;
  }

  @media (min-width: 1000px) {
    margin-bottom: 40px;
  }
}
