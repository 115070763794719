.route-info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #011d5f67;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.route-info-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-weight: bold;
  font-size: 30px;
  margin: 10px;
  background: var(--color-primario);
  color: var(--color-secundario);
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s;
}

.route-info-modal-container {
  background-image: url("../../public/background_arrows.png");
  padding: 15px;
  position: relative;
  border-radius: 10px;
  width: 90%;
  text-align: center;
  height: 90%;

  @media (min-width: 1000px) {
    padding: 50px;
  }
}

.route-info-modal-title-container {
  margin-bottom: 20px;
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
}

.route-info-modal-title {
  text-transform: uppercase;
  background-color: var(--color-primario);
  padding: 20px;
  color: var(--color-fondo);
  width: max-content;
  margin-bottom: 15px;
  border-radius: 5px;

  @media (max-width: 1000px) {
    font-size: small;
    max-width: 90%;
  }

  @media (min-width: 1000px) {
    margin-bottom: 40px;
  }
}

.route-info-summary {
  background-color: var(--color-fondo);
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex; /* Espacio después de la línea */
  justify-content: space-between;
  @media (min-width: 1000px) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.summary-duration {
  display: inline-flex;
  color: var(--color-primario);
  padding: 20px;
  font-weight: 500;
  font-size: small;
  align-items: center;
  @media (min-width: 1000px) {
    font-size: 18px;
  }
}

.summary-price {
  display: inline-flex;
  color: var(--color-fondo);
  background-color: var(--color-primario);
  padding: 20px;
  font-weight: 500;
  font-size: x-small;
  align-items: center;

  @media (min-width: 1000px) {
    font-size: 18px;
  }
}

.route-info-stages {
  max-height: 82%;
  overflow: auto;
  @media (min-width: 1000px) {
    height: 75%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.route-info-stages::-webkit-scrollbar {
  display: none; /* Para ocultar la barra de desplazamiento en Webkit (Chrome, Safari) */
}
.route-info-stage-content {
  background-color: var(--color-fondo);
  padding: 20px;
  border-radius: 5px;

  @media (min-width: 1000px) {
    width: 45%;
  }
}

.route-info-stage-content:not(:last-child) {
  margin-bottom: 30px;
}

.stage-content-title {
  color: var(--color-primario);
  text-transform: uppercase;
  text-align: start;
  border-bottom: 5px solid;
  margin-bottom: 15px;

  @media (max-width: 1000px) {
    font-size: small;
  }
}

.stage-content-left {
  margin-bottom: 15px;
  background-color: var(--color-primario);
  /* @media (max-width: 1000px) { */
  display: flex;
  justify-content: space-around;
  /* } */
}

.stage-content-description.expanded {
  white-space: normal; /* Permite que el texto se expanda */
  overflow: visible; /* Muestra todo el contenido */
  max-height: none; /* Remueve la restricción de altura */
}

.stage-content-description {
  color: var(--color-texto);
  text-align: left;

  font-size: medium;
  line-height: 1.5; /* Espaciado entre líneas */
  max-height: calc(1.5em * 3); /* Calcula la altura para 3 líneas, ajusta según lo que necesites */
  overflow: hidden; /* Oculta el texto que exceda */
  display: -webkit-box; /* Habilita la caja flexible para el recorte */
  line-clamp: 3; /* Define el número máximo de líneas visibles */
  -webkit-box-orient: vertical; /* Establece la orientación de las cajas flexibles */
  text-overflow: ellipsis; /* Muestra '...' al final del texto */
  transition: max-height 0.3s ease; /* Transición suave si cambias la altura */
}

@media (min-width: 1000px) {
  .stage-content-description {
    margin-bottom: 15px;
  }
}

.stage-container {
  @media (min-width: 1000px) {
    /* display: flex;  */
    justify-content: space-between;
  }
}

.stage-places {
  @media (min-width: 1000px) {
    /* display: flex; */
  }
}

.stage-content-right {
  margin-top: 20px;
  @media (min-width: 1000px) {
    margiin-top: 0; /* Espacio después de la línea */
  }
}

.stage-content-aux-info {
  text-align: left;
  font-weight: 400;
  display: flex;
  font-size: x-small;
  padding: 10px;

  border-radius: 5px;
  background: var(--color-primario);
  width: fit-content;
  margin: 5px 0;

  align-items: center;

  @media (min-width: 1000px) {
    font-size: 18px;
    /* max-width: min-content; */
    padding: 20px;
  }
}

.aux-info-title {
  display: flex;
  margin-right: 5px;
  align-items: center;
  text-transform: uppercase;
  color: var(--color-secundario);
  font-weight: 600;
}

.aux-info-text {
  color: var(--color-fondo);
  text-transform: capitalize;
}

.aux-info-image {
  object-fit: cover;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-style: solid;
  border-color: var(--color-primario);
  width: 420px;
  height: 300px;
}

.route-info-button-book {
  background: var(--color-secundario);
  color: var(--color-primario);
  text-transform: uppercase;
  border: none;
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  font-size: small;
  height: 45px;

  @media (max-width: 1000px) {
    margin-top: 10px;
  }

  @media (min-width: 1000px) {
    margin-top: 20px;
    height: 50px;
    font-size: 25px;
  }
}

.toggle-button {
  background: none;
  border: none;
  color: var(--color-primario); /* Ajusta según tu paleta */
  cursor: pointer;
  font-size: x-small;
  font-weight: 400;
  margin-top: 5px;
  text-transform: uppercase;
  transition: color 0.3s;
}

.route-info-icons {
  color: var(--color-secundario);
  margin-right: 10px;
  @media (min-width: 1000px) {
    font-size: 25px;
  }
}
