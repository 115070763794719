.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #011d5f67;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  text-align: center;
}

.contact-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: var(--color-primario);
  cursor: pointer;
  transition: color 0.3s;
}

.title {
  text-transform: uppercase;
  color: var(--color-primario);
}

.contact-form {
  max-width: 350px;
  margin: 0 auto;
}

.contact-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(221, 218, 218);
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.contact-input.content {
  height: 200px;
  text-align: start;
}

.contact-content-counter {
  font-size: 14px;
  color: var(--color-primario);
  text-align: right;
}

.contact-msg {
  color: #bf1650;
  font-size: 17px;
  text-align: left;
}

.contact-msg::before {
  display: inline;
  content: "⚠ ";
}

.contact-label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: var(--color-primario);
  font-size: 15px;
  font-weight: 200;
}

.error {
  border: 1px solid #bf1650;
}

/* Botón dentro de la modal */
.closeButton {
  background: var(--color-primario);
  color: var(--color-secundario);
  text-transform: uppercase;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

.closeButton:hover {
  background: var(--color-secundario);
  color: var(--color-primario);
}
