/* Estilos del header */
.header {
  grid-area: header;
  background-color: var(--color-primario);
  padding: 0 5px 0 5px;
  height: auto;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 20px 0 20px;
}

.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu ul {
  font-family: "Poppins", serif;
  font-size: 15px;
  align-items: center;
  text-transform: uppercase;
  list-style: none;
  display: flex;
  gap: 20px;

  @media (min-width: 1000px) {
    font-size: 20px;
  }
}

.menu div {
  font-family: "Poppins", serif;
  text-decoration: none;
  margin-right: 10px;
  color: var(--color-fondo);
  font-weight: 500;
  transition: color 0.3s;
  cursor: pointer;
}

.menu div:hover {
  color: var(--color-secundario);
}

.link-header {
  text-decoration: none;
}

.logo-container {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center; /* Centra verticalmente el logo y el nombre */

  @media (max-width: 1000px) {
    width: 20%;
  }
}

.logo {
  height: 30px;
  margin-right: 5px;
  padding-bottom: 2px;

  @media (min-width: 1000px) {
    height: 50px;
    margin-right: 10px;
    padding-bottom: 5px;
  }
}

.company-name {
  text-align: center;
  font-family: "Poppins", serif; /* Usa la fuente elegida */
  text-transform: uppercase;
  font-size: 15px; /* Tamaño de la fuente */
  margin-right: 20px;
  color: var(--color-primario); /* Color del texto */

  @media (min-width: 1000px) {
    font-size: 30px; /* Tamaño de la fuente */
    margin-right: 40px;
  }
}

.company-name.first {
  color: var(--color-fondo);
  margin-right: 0;
}

.company-name.second {
  color: var(--color-secundario);
}

.language-switcher {
  background: none;
  border: none;
  color: white;
  font-size: 15px;
  cursor: pointer;
  margin-left: 20px;
  transition: color 0.3s ease;
  text-transform: uppercase;

  @media (min-width: 1000px) {
    font-size: 20px;
  }
}

.language-switcher:hover {
  color: var(--color-secundario); /* Cambia a un color destacado */
}

/* Submenu */
.language-menu {
  position: relative;
}

.submenu {
  position: absolute;
  top: 40px;
  left: 0;
  background: var(--color-primario);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: var(--color-fondo);
}

.submenu li {
  padding: 0.5rem 0;
  cursor: pointer;
}

.submenu li:hover {
  color: var(--color-secundario);
}
