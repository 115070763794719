.book-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #011d5f67;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.book-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  text-align: center;
}

.book-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: var(--color-primario);
  cursor: pointer;
  transition: color 0.3s;
}

.book-form-services-icon {
  color: var(--color-primario);
  margin: 5px 5px 0 0;
}

.book-modal-title {
  margin-bottom: 15px;
  text-transform: uppercase;
  color: var(--color-primario);
}

.book-form-container {
  @media (max-width: 1000px) {
    height: 80vh;
    overflow-y: auto;
  }
  @media (min-width: 1000px) {
    display: flex;
    height: 100%;
  }
}

.book-form-left-content {
  border-right: 1px solid #ddd;

  @media (min-width: 1000px) {
    padding: 20px;
    width: 65%;
    height: 80vh;
    overflow-y: auto;
  }
}
/* Oculta el track y muestra solo el thumb */
.book-form-left-content::-webkit-scrollbar {
  width: 6px; /* Ancho mínimo para que sea discreto */
}

.book-form-left-content::-webkit-scrollbar-track {
  background: transparent; /* Track invisible */
}

.book-form-left-content::-webkit-scrollbar-thumb {
  background: #888; /* Color gris del thumb */
  border-radius: 4px; /* Bordes redondeados para un look moderno */
}

/* Cambia el color al hacer hover sobre el thumb */
.book-form-left-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color más oscuro en hover */
}

/* Para navegadores no WebKit (Firefox) */
.book-form-left-content {
  scrollbar-width: thin; /* Barra delgada */
  scrollbar-color: #888 transparent; /* Thumb gris sobre fondo transparente */
}

.book-form-left-label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: var(--color-primario);
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
}

.book-form-left-label-extra-service {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 0;
  margin-top: 20px;
  color: var(--color-primario);
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
}

.book-form-left-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(221, 218, 218);
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.book-form-left-hide-button {
  font-size: 10px;
  text-transform: uppercase;
  color: var(--color-secundario);
  background: var(--color-primario);
  padding: 5px;
  border-radius: 5px;
  font-weight: 400;
  cursor: pointer;
  justify-content: start;
  display: grid;
  width: max-content;
}

.book-form-left-contact-module {
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
}

.book-form-sevices-extra {
  display: grid;
}

.book-form-services-check {
  width: 100%;
  display: inline-flex;
  justify-content: start;
  margin-bottom: 15px;
  color: var(--color-texto);
  text-align: left;
}

.boook-form-services-check-input {
  color: var(--color-primario);
  margin-right: 10px;
}

.book-form-services-check-container {
  display: flex;
  justify-content: space-between; /* Alinea los elementos a los extremos */
  align-items: center; /* Centra verticalmente si los elementos tienen alturas diferentes */
  width: 100%; /* Opcional, asegura que ocupe todo el espacio disponible */
}

.check-service-name {
  text-align: left; /* Asegura que el texto quede a la izquierda */
  max-width: 170px;
  color: var(--color-texto);
}

.check-service-price {
  color: var(--color-fondo);
  background: var(--color-primario);
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0;
  text-align: right; /* Asegura que el texto quede a la derecha */
}

.book-form-left-content-end,
.book-form-left-content-start {
  @media (min-width: 1000px) {
    width: 48%;
  }
}

.book-form-left-content-start {
  margin-top: 2px;
  margin-bottom: 10px;
}

.book-form-left-content-end {
  margin-bottom: 10px;
}

.book-form-right-content {
  background: var(--color-primario);
  border-radius: 5px;
  padding: 20px;
  @media (min-width: 1000px) {
    width: 35%;
    height: 80vh;
    overflow-y: auto;
    margin-left: 25px;
  }
}

.book-form-right-title {
  text-align: left;
  text-transform: uppercase;
  color: var(--color-secundario);
  margin-bottom: 20px;
}

.book-form-right-content-submit.active {
  background-color: #007bff;
  color: white;
}

.book-form-right-field-service {
  width: 100%; /* Asegura que el contenedor ocupe todo el espacio disponible */
  margin: 10px;
}

.book-resume-services-icon {
  color: var(--color-secundario);
  margin: 5px 5px 0 0;
}

.book-form-right-label-service {
  display: block; /* Hace que el título se ubique en una línea */
  text-align: left; /* Alinea el título a la izquierda */
  margin-bottom: 10px; /* Agrega espacio debajo del título */
  color: var(--color-secundario);
  text-transform: uppercase;
}

.services-list {
  display: flex;
  flex-direction: column; /* Pone los servicios en una columna */
  gap: 10px; /* Añade espacio entre cada servicio */
}

.book-form-services-resume-container {
  display: flex;
  justify-content: space-between; /* Coloca el nombre y el precio en los extremos */
  padding: 5px 0; /* Espaciado entre cada servicio */
  border-bottom: 1px solid #ddd; /* Línea debajo de cada servicio */
}

.resume-service-name {
  flex: 1; /* Hace que el nombre ocupe todo el espacio disponible */
  text-align: left; /* Alinea el nombre a la izquierda */
  color: var(--color-fondo);
}

.resume-service-name-field {
  flex: 1; /* Hace que el nombre ocupe todo el espacio disponible */
  text-align: left; /* Alinea el nombre a la izquierda */
  color: var(--color-fondo);
  text-transform: capitalize;
}

.resume-service-price {
  text-align: right; /* Alinea el precio a la derecha */
  color: var(--color-fondo);
  margin-right: 10px;
}

.book-form-right-field {
  margin: 10px;
  justify-content: start;
  /* display: grid; */
  @media (min-width: 1000px) {
    display: flex;
    justify-self: left;
  }
}

.book-form-right-field-mail {
  margin: 10px;
  display: grid;
  justify-content: start;
}

.book-form-contact-icon {
  color: var(--color-primario);
  margin-left: 10px;
}

.book-form-contact-icon.mail {
  padding-top: 3px;
}
.input-error {
  border: 1px solid rgb(222, 104, 104);
}

.error-message {
  color: rgb(222, 104, 104);
  font-size: 0.8rem;
  margin-top: 4px;
}

.book-form-right-label {
  text-transform: uppercase;
  color: var(--color-secundario);
  @media (max-width: 1000px) {
    display: block;
    text-align: start;
  }
}

.book-form-right-label-mail {
  text-transform: uppercase;
  color: var(--color-secundario);
  text-align: start;
  margin-bottom: 10px;
}

.book-form-right-placeholder {
  color: var(--color-fondo);
  margin-left: 10px;
  text-transform: capitalize;
}

.book-form-right-mail-placeholder {
  color: var(--color-fondo);
  text-align: start;
}

.book-form-horizontal-divider {
  height: 2px; /* Thickness of the line */
  background-color: #ccc; /* Line color */
  margin: 20px 0; /* Space around the line */
  width: 100%; /* Full width */
}

.number-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.book-form-left-input-end {
  width: 80px; /* Tamaño del input */
  margin-right: 10px; /* Espacio entre el input y el bloque de precio */
  padding: 5px;
}

.price-details {
  margin-bottom: 10px; /* Espacio entre el input y el bloque de detalles de precio */
  font-size: 10px;
  color: #555; /* Color gris para los textos */
}

.price-details p {
  margin: 5px 0;
}

.book-form-price-subtitle {
  font-size: small;
  color: var(--color-fondo);
  text-align: start;
  margin-left: 10px;
}

.book-form-right-content-submit {
  margin: 20px 20px 0 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end;
  text-transform: uppercase;
  width: 70%;
}

.book-form-right-content-submit.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.bookk-form-budget-button-real {
  color: var(--color-fondo);
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  font-size: 11px;
}
.bookk-form-budget-button {
  color: var(--color-fondo);
  text-transform: uppercase;
  text-decoration: underline;
  /* cursor: pointer; */
  margin-top: 5px;
  font-size: 11px;
}

.book-form {
  margin: 0 20px;
}

.book-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(221, 218, 218);
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.book-input.content {
  height: 200px;
  text-align: start;
}

.book-content-counter {
  font-size: 14px;
  color: var(--color-primario);
  text-align: right;
}

.book-msg {
  color: #bf1650;
  font-size: 17px;
  text-align: left;
}

.book-msg::before {
  display: inline;
  content: "⚠ ";
}

.book-label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: var(--color-primario);
  font-size: 15px;
  font-weight: 400;
}

.error {
  border: 1px solid #bf1650;
}

.book-form-footer {
  display: flex;
  justify-content: space-around;
  gap: 25px;
}

.book-form-price {
  padding: 5px;
  color: var(--color-fondo);
  border-radius: 5px;
  background-color: var(--color-primario);
}
/* Botón dentro de la modal */
.book-closeButton {
  background: var(--color-primario);
  color: var(--color-secundario);
  text-transform: uppercase;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

.book-closeButton:hover {
  background: var(--color-secundario);
  color: var(--color-primario);
}

.book-extra-night-content {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.book-form-left-input-extra-night {
  display: block;
  box-sizing: border-box;
  width: 70%;
  border-radius: 4px;
  border: 1px solid rgb(221, 218, 218);
  padding: 10px 15px;
  /* margin-bottom: 15px; */
  font-size: 14px;
}

.book-form-left-input-prefix {
  display: block;
  box-sizing: border-box;
  width: 28%;
  border-radius: 4px;
  border: 1px solid rgb(221, 218, 218);
  padding: 10px 15px;
  margin-right: 5px;
  font-size: 14px;

  @media (min-width: 1000px) {
    width: 25%;
  }
}

.book-extra-night-sutitle {
  color: #555;
  font-size: 10px;
  text-transform: uppercase;
}

.book-service-extra-price-sutitle {
  color: #555;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 3px;
}
