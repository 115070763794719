/* ONLY COLORS USED ON THE APP  */
:root {
  --color-primario: #011d5f;
  --color-secundario: #ffdd58;
  --color-fondo-secundario: #fff7d6;
  --color-fondo: #fff;
  --color-texto: #000614;
  --breakpoint-mobile: 1000px;
  --breakpoint-tablet: 1024px;
}

/* Estilos generales */
* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", serif;
  background-color: var(--color-fondo);
  color: var(--color-texto);
  padding-top: 60px;

  @media (min-width: 1000px) {
    padding-top: 80px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button {
  font-family: "Poppins", serif;
}
