/* Estilos del footer */
.footer {
  /* grid-area: footer; */
  background-color: var(--color-primario);
  color: var(--color-fondo);
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 20px;
  font-size: 14px;
}

.footer-container {
  margin-bottom: 15px;

  @media (min-width: 1000px) {
    justify-content: space-around;
    display: flex;
  }
}

.footer-policies {
  text-align: start;
  padding: 20px;
}

.footer-policies-title {
  text-transform: uppercase;
  color: var(--color-secundario);
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

.footer-policies-text {
  color: var(--color-fondo);
  /* margin-left: 20px; */
  text-transform: capitalize;
  align-items: center;
}

.footer-policies-content {
  padding: 10px 0;
}

.footer-contact {
  padding: 20px;
  text-align: start;
}

.footer-contact-content {
  display: flex;
  padding: 10px 0;
  align-items: center;
}

.footer-contact-icon {
  margin-right: 5px;
  color: var(--color-fondo);
}
