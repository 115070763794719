/* Estilo base para la notificación flotante */
.floating-message {
  position: fixed;
  bottom: 20px; /* Ubicación al fondo */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  z-index: 9999; /* Para asegurarse de que se muestre por encima de otros elementos */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Sombras para destacar */
  opacity: 0.9; /* Ligera transparencia */
  transition: opacity 0.5s ease;
}

/* Estilo para una notificación exitosa */
.floating-message.success {
  background-color: var(--color-primario);
  color: var(--color-secundario); /* Verde */
}

/* Estilo para una notificación de error */
.floating-message.error {
  background-color: #f44336; /* Rojo */
}

/* Estilo para una notificación de advertencia */
.floating-message.warning {
  background-color: #ff9800; /* Naranja */
}

/* Estilo para una notificación informativa */
.floating-message.info {
  background-color: #2196f3; /* Azul */
}
