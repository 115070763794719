/* Estilos del contenido principal */
.content {
  font-family: "Poppins", serif;
  text-align: center;
  height: 320px;
  @media (min-width: 1000px) {
    height: 400px;
  }

  @media (min-width: 1800px) {
    height: 320px;
  }
}

.home-logo {
  height: 60px;
}

.background-container {
  position: relative;
  min-height: 320px;
  background-image: url("../../public/pexels-jokassis-11690243.jpg"); /* Ruta a tu imagen local */
  background-size: cover;
  background-position: center;
  @media (min-width: 1000px) {
    height: 400px;
  }

  @media (min-width: 1800px) {
    height: 320px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo negro con transparencia del 80% */
  /* display: flex; Activa flex para centrar contenido */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  color: white;
  text-align: center;
  padding: 20px;
}

.home-title-container {
  display: inline-flex;
  justify-content: center;
}

.home-title {
  font-size: 20px;
  color: var(--color-fondo);

  @media (min-width: 1000px) {
    font-size: 32px;
  }
}

.home-title.second {
  color: var(--color-secundario);
  text-transform: uppercase;
}

.home-description {
  margin-top: 20px;
  text-align: start;

  @media (min-width: 1000px) {
    text-align: center;
    font-size: 25px;
  }
}
