/* Layout usando CSS Grid */
.container {
  font-family: "Poppins", serif;
  display: grid;
  grid-template-rows: 80px 1fr 100px;
  grid-template-areas:
    "header"
    "content"
    "footer";
  height: 100vh;
  margin: 0; /* Márgenes laterales */
}
