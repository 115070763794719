.routes-container {
  background-image: url("../../public/background_arrows.png");
  padding: 50px;
}

.route-container {
  justify-content: space-around;

  @media (min-width: 1000px) {
    gap: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.routes-container-title {
  background: var(--color-primario);
  width: fit-content;
  text-align: start;
  text-transform: uppercase;
  color: var(--color-secundario);
}

.route-content {
  display: grid;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 25px;
  background: var(--color-fondo);
  margin-top: 20px;
  @media (min-width: 1000px) {
    width: 45%;
  }
}

.route-image {
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-style: solid;
  object-fit: cover;
  border-color: var(--color-primario);
  justify-self: center;
  height: 320px;
  width: 460px;
}

.route-title {
  font-size: 20px;
  font-weight: 600;
  padding-top: 7px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--color-primario);
}

.route-description {
  font-size: 22px;
  margin-top: 10px;
  color: var(--color-texto);
  text-align: start;
  max-height: 350px;
  overflow: hidden;
  position: relative;
}

.route-button-more {
  background: var(--color-secundario);
  color: var(--color-primario);
  text-transform: uppercase;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  justify-self: center;
  margin-top: 20px;

  @media (min-width: 1000px) {
    width: 20%;
  }
}

.route-button-book {
  background: var(--color-primario);
  color: var(--color-secundario);
  text-transform: uppercase;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  width: 70%;
  margin-top: 20px;
  justify-self: center;
}
