.package-container {
  /* padding: 20px; */
  background: var(--color-fondo);
  justify-content: space-around;

  @media (min-width: 1000px) {
    display: flex;
  }
}

.package-container.true {
  padding: 20px;
  margin-bottom: 20px;
}

.package-title-container {
  display: flex;
  justify-content: space-between;
}

.package-price {
  padding: 5px;
  color: var(--color-fondo);
  border-radius: 5px;
  background-color: var(--color-primario);
}

.package-title {
  color: var(--color-secundario);
  font-family: "Poppins", serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  font-weight: 600;

  @media (min-width: 1000px) {
    text-align: start;
  }
}

.package-title.true {
  color: var(--color-secundario);
}

.package-image {
  display: grid;
  justify-content: center;
  width: 25vw;
  height: 25vh;
  margin: 10px;
}

.package-description {
  margin-top: 15px;
  text-align: start;
  font-size: 15px;
  color: var(--color-fondo);

  @media (min-width: 1000px) {
    font-size: 25px;
  }
}

.package-description.true {
  color: var(--color-fondo);
}

.package-wrapper {
  @media (min-width: 1000px) {
    align-items: center;
    display: flex;
  }
}

.package-wrapper.true {
  /* padding: 0px 50px; */
}

.package-wrapper.true:not(:last-child) {
  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
  @media (min-width: 1000px) {
    padding-right: 20px;
  }
}

.package-wrapper.undefined {
  @media (max-width: 1000px) {
    margin-bottom: 0;
  }
  @media (min-width: 1000px) {
    padding-right: 0;
  }
}

.package-button {
  background: var(--color-primario);
  color: var(--color-secundario);
  text-transform: uppercase;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 15px;
  justify-content: center;
  width: 100%;

  @media (min-width: 1000px) {
    width: 50%;
  }
}

.package-button:hover {
  background: var(--color-primario);
  color: var(--color-secundario);
}

.package-content.selected {
  background: var(--color-primario);
  box-shadow: 2px;
  border-radius: 5px;
  padding: 15px;
}

.package-wrapper.undefined {
  @media (min-width: 1000px) {
    width: 100%;
  }
}

.package-content-image-bcgd.classic {
  position: relative;
  height: 300px;
  width: 100%;
  background-image: url("../../../public/clasic.jpg"); /* Ruta a tu imagen local */
  background-size: cover;
  background-position: center;
}

.package-content-image-bcgd.basic {
  position: relative;
  /* min-height: 175px; */
  height: 300px;
  width: 100%;
  background-image: url("../../../public/basic.png"); /* Ruta a tu imagen local */
  background-size: cover;
  background-position: center;
}

.package-content.image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(1 29 95 / 55%); /* Fondo negro con transparencia del 80% */
  /* display: flex; Activa flex para centrar contenido */
  justify-content: center; /* Centra horizontalmente */
  display: grid;
  align-items: center; /* Centra verticalmente */
  color: white;
  text-align: center;
  padding: 20px;
}

.package-content-form {
  @media (min-width: 1000px) {
    margin-top: 20px;
  }

  @media (min-width: 1000px) {
    gap: 20px;
  }
}

.package-content-form.selected {
  background: var(--color-primario);
  box-shadow: 2px;
  border-radius: 5px;
  padding: 15px;
}
.package-title-container-form {
  display: flex;
  justify-content: space-between;
}

.package-price-form {
  padding: 5px;
  color: var(--color-fondo);
  border-radius: 5px;
  background-color: var(--color-primario);
}

.package-title-form {
  color: var(--color-primario);
  font-family: "Poppins", serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  font-weight: 600;

  @media (min-width: 1000px) {
    text-align: start;
  }
}

.package-title-form.true {
  color: var(--color-secundario);
}
.package-description-form {
  margin-top: 15px;
  text-align: start;
  font-size: 13px;
  color: var(--color-texto);

  @media (min-width: 1000px) {
    font-size: 16px;
  }
}

.package-description-form.true {
  color: var(--color-fondo);
}

.package-opt-shown {
  padding: 5px;
  color: var(--color-primario);
  border-radius: 5px;
  background-color: var(--color-secundario);
  text-transform: uppercase;
}
